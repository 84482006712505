import React from 'react';
import PageSection from '../ui/PageSection';
import TextContainer from '../ui/TextContainer';
import styles from './LegalLayout.module.scss';
import MarketingLayout from './MarketingLayout';

interface LegalLayoutProps {
  children: React.ReactNode;
  title: string;
}

const LegalLayout: React.SFC<LegalLayoutProps> = ({ children, title }) => (
  <MarketingLayout title={title}>
    <PageSection>
      <TextContainer center>
        <div className={styles.legalContainer}>{children}</div>
      </TextContainer>
    </PageSection>
  </MarketingLayout>
);

export default LegalLayout;
