import { Link } from 'gatsby';
import React from 'react';
import LegalLayout from '../components/layout/LegalLayout';

const name = 'Fallacy Labs, Inc. DBA Slab, Inc.';
const updatedAt = 'March 13, 2020';
const privacy = <Link to="/privacy/">Privacy Policy</Link>;
const providers = <Link to="/providers/">Service Provider List</Link>;
const terms = <Link to="/terms/">Terms of Use</Link>;
const support = <a href="mailto:support@slab.com">support@slab.com</a>;
const phone = '1-833-GET-SLAB';
const mail = (
  <p className="address">
    Slab
    <br />
    340 S Lemon Ave #8017
    <br />
    Walnut, CA 91789
  </p>
);

const Privacy: React.SFC = () => (
  <LegalLayout title="Privacy Policy">
    <section>
      <h1>Privacy Policy</h1>
      <p>Last updated: {updatedAt}</p>
      <p>
        {name} (&quot;
        <u>Slab</u>,&quot; &quot;
        <u>we</u>
        &quot; or &quot;
        <u>us</u>
        &quot;) is committed to protecting your privacy. This Privacy Policy
        describes how we collect, store, use and distribute information through
        our software, website, documentation, and related services (together,
        the &quot;
        <u>Services</u>
        &quot;).
      </p>
      <p>
        Capitalized terms not defined in this Privacy Policy have the meaning as
        set forth in the Terms of Service for the Services, which can be found
        at {terms}.
      </p>
      <p>
        If you are visiting us from the European Union or European Economic
        Area, please be sure to read the section entitled &quot;
        <u>Additional Information for Users in the European Economic Area</u>
        &quot; for information on how we comply with privacy laws applicable to
        you.
      </p>
    </section>
    <section>
      <h2>1. Consent</h2>
      <p>
        By using the Services, you consent to the use of your Personal
        Information as described in this Privacy Policy. If you are not of legal
        age to form a binding contract (in many jurisdictions, this age is 18),
        you may only use the Services and disclose information to us with your
        parent’s or legal guardian’s express consent. Furthermore, children
        under the age of 13 cannot use or register for the Services in any way.
        Review this Privacy Policy with your parent or legal guardian make sure
        you understand it. Except as set forth in this Privacy Policy, your
        Personal Information will not be used for any other purpose without your
        consent. We do not actively collect Personal Information for the purpose
        of sale of such information in a way that specifically identifies the
        individual (i.e. we don’t sell customer lists). You may withdraw your
        consent to our processing of your Personal Information at any time.
        However, withdrawing consent may result in your inability to continue
        using some or all of the Services.
      </p>
    </section>
    <section>
      <h2>2. Collection of Information</h2>
      <h3>Personal Information</h3>
      <p>
        When registering to use the Services, we may require or ask you to
        provide certain personally identifiable information (these are referred
        to below as your &quot;
        <u>Personal Contact Information</u>
        &quot;). The Personal Contact Information that we require you to provide
        may include, but is not limited to, the following:
      </p>
      <ul>
        <li>E-mail</li>
        <li>Full name or alias (can be fictitious)</li>
        <li>Company name</li>
        <li>Company subdomain</li>
      </ul>
      <p>
        We may also ask you to provide certain other Personal Contact
        Information, which is not required and you may decline to provide. Such
        information may include, but is not limited to:
      </p>
      <ul>
        <li>Profile picture</li>
        <li>Title</li>
        <li>Job description</li>
      </ul>
      <p>
        When purchasing the Services, we will require you to provide financial
        and billing information, such as billing name and address, and credit
        card number (&quot;
        <u>Billing Information</u>
        &quot;). Your Personal Contact Information and your Billing Information,
        together with any other information we gather through the Services that
        may be used to identify, contact, or locate you individually, are
        collectively referred to herein as your &quot;
        <u>Personal Information</u>
        .&quot;
      </p>
      <p>
        We may use your Personal Information to contact you with newsletters,
        marketing or promotional materials and other information that may be of
        interest to you. You may opt out of receiving any, or all, of these
        communications from us by following the unsubscribe link or instructions
        provided in any email we send. While there are limited circumstances in
        which we may give third parties access to your Personal Information, the
        purposes of which are described below, we do not permit any third
        parties with whom we may share your Personal Information to contact you
        with their own marketing or promotional materials.
      </p>
      <p>
        You may also be provided the ability to provide us with the name and
        email address of your coworkers to allow us to send them a referral link
        to sign up for our Services. Applicable law may require you obtain
        consent from your coworker before sharing their name and email address
        with us and authorizing us to communicate with them. Such communications
        may contain web beacons that provide us information about your
        coworkers’ interaction with the emails we send them. By submitting any
        third-party personal information to us and allowing us to communicate
        with such third party, you agree that you have all required
        authorization and consents required under applicable law.
      </p>
      <h3>Data, Diagnostic & Login Information</h3>
      <p>
        You may be able to create, upload, publish, transmit, distribute,
        display, store or share information, data, text, graphics, video,
        messages or other materials using our Services (this is collectively
        referred to below as &quot;
        <u>Data</u>
        &quot;). Some of this Data may be stored and maintained on our servers.
      </p>
      <p>
        If you run into technical errors in the course of using the Services, we
        may automatically obtain a crash report along with certain logging
        information from your system documenting the error (&quot;
        <u>Diagnostic Information</u>
        &quot;). Such information may contain information regarding your
        Operating System version, hardware, browser version, and your e-mail
        address, if provided. In some instances, we may request your permission
        to obtain some of your user-generated content to which the technical
        error may relate. This is strictly optional. We will only use such
        content to diagnose the error and help improve our Services to avoid
        such errors in the future.
      </p>
      <p>
        Additionally, certain login information may be maintained in a cookie
        stored locally on your personal computing device (i.e. not on a server)
        in order to streamline the login process (&quot;
        <u>Login Information</u>
        &quot;).
      </p>
      <h3>Usage and Analytics Information</h3>
      <p>
        As you use our Services, we may also collect information through the use
        of commonly-used information-gathering tools, such as cookies, log
        files, Web tokens, and Web beacons. Collectively, this information is
        referred to as &quot;
        <u>Usage and Analytics Information</u>
        .&quot;
      </p>
      <h4>Cookies</h4>
      <p>
        Some of our Service Providers use cookies or similar technologies to
        analyze trends, administer the website, track users’ movements around
        the website, and to enhance your user experience.
      </p>
      <p>
        Cookies allow you, as a user, to navigate between pages efficiently,
        storing your preferences, and generally improving your experience on our
        sites. As a user, your web browser places cookies on your hard drive for
        record-keeping purposes and sometimes to track information about you.
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </p>
      <p>
        For more information on cookies as they pertain to our Service, please
        see Section 12.
      </p>
      <h4>Log Files</h4>
      <p>
        As is true of most websites, we gather certain information automatically
        to analyze trends in the aggregate and administer the Services. This
        information may include your Internet Protocol (IP) address (or the
        proxy server you use to access the World Wide Web), your browser type,
        the pages and files you viewed, your operating system, and date/time
        stamps associated with your usage. Due to Internet communications
        standards, when you visit or use our websites and Services, We
        automatically receive the URL of the website from which you came and the
        website to which you go when you leave our website. This information is
        used to analyze overall trends, to help us improve the Services, to
        track and aggregate non-personal information, and to provide the
        Services. For example, we use IP addresses to monitor the regions from
        which customers and visitors navigate the Sites. We also collects IP
        addresses from customers when they log into the Services as part of the
        &quot;IP Restriction&quot; security feature as well as to maintain audit
        logs.
      </p>
      <h4>Web Beacons</h4>
      <p>
        We use web beacons alone or in conjunction with cookies to compile
        information about your usage of the Services and interaction with emails
        from us. Web beacons are clear electronic images that can recognize
        certain types of information on your computer, such as cookies, when you
        viewed a particular website or Service tied to the web beacon, and a
        description of a website or Service tied to the web beacon. For example,
        we may place web beacons in marketing emails that notify us when you
        click on a link in the email that directs you to one of our websites. We
        also use web beacons to operate and improve the Services and our email
        communications.
      </p>
      <h3>Geo-Location Information</h3>
      <p>
        We do not use GPS technology to collect any information regarding your
        precise real-time geo-location while using the Services. However, we may
        use elements of your Usage and Analytics Information (such as your IP
        address) to determine your generalized location. This information is
        referred to as &quot;
        <u>Generalized Geo-Location Information</u>
        .&quot;
      </p>
    </section>
    <section>
      <h2>Use of Information</h2>
      <p>We use the information we collect in the following ways:</p>
      <table>
        <thead>
          <tr>
            <th>Information Collected</th>
            <th>Use</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Personal Contact Information</td>
            <td>
              We use this information to manage your account, to provide the
              Services, to maintain our customer/visitor lists, to respond to
              your inquiries or request feedback, for identification and
              authentication purposes, for service improvement, and to address
              issues like malicious use of the Services. We may also use
              Personal Contact Information for limited marketing purposes,
              namely, to contact you to further discuss your interest in the
              Services, and to send you information about us or our partners.
              <br />
              You may opt out of receiving any, or all, of these communications
              from us by following the unsubscribe link or instructions provided
              in any email we send.
            </td>
          </tr>
          <tr>
            <td>Billing Information</td>
            <td>
              We use Billing Information to manage your account, to provide the
              Services, and to check the financial qualifications of prospective
              customers and to collect payment for the Services. We may use a
              third-party service provider to manage credit card processing. If
              we do so, such a service provider will not be permitted to store,
              retain, or use Billing Information except for the sole purpose of
              credit card processing on our behalf.
            </td>
          </tr>
          <tr>
            <td>Data, Diagnostic Information and Login Information</td>
            <td>
              We use this information for the purpose of administering and
              improving our Services to you. We may also use this information in
              a de-identified, anonymous way in conjunction with an analytics
              service such as Google Analytics to monitor and analyze use of the
              Services, for the Services’ technical administration, and to
              increase the Services’ functionality and user-friendliness.
            </td>
          </tr>
          <tr>
            <td>Usage and Analytics Information</td>
            <td>
              We may use your Usage and Analytics Information in a
              de-identified, anonymous way in conjunction with an analytics
              service such as Google Analytics to monitor and analyze use of the
              Services, for the Services’ technical administration, to increase
              the Services’ functionality and user-friendliness, and to verify
              users have the authorization needed for the Services to process
              their requests.
            </td>
          </tr>
          <tr>
            <td>Generalized Geo-Location Information</td>
            <td>
              We may use this information for the purpose of administering and
              improving our Services to you, such as by providing you with
              relevant advertisements and promotions. We may also use your
              Generalized Geo-Location Information in an anonymized manner in
              conjunction with an analytics service such as Google Analytics to
              monitor and analyze use of the Services, for the Services’
              technical administration, and to increase the Services’
              functionality and user-friendliness.
            </td>
          </tr>
        </tbody>
      </table>
      <p>
        Please note that if you are registering for or using the Services as an
        administrator on behalf of an organization, you may be able to view the
        activity and/or content associated with your Associated users use of the
        Services, including, but not limited to, their Personally Identifiable
        Information.
      </p>
      <p>
        If we plan to use your Personal Information in the future for any other
        purposes not identified above, we will only do so after informing you by
        updating this Privacy Policy. See further the section of this Privacy
        Policy entitled &quot;Amendment of this Privacy Policy&quot;.
      </p>
    </section>
    <section>
      <h2>4. Do Not Track</h2>
      <p>
        We do not support Do Not Track. Do Not Track is a preference you can set
        in your web browser to inform websites that you do not want to be
        tracked. You can enable or disable Do Not Track by visiting the
        Preferences or Settings page of your web browser.
      </p>
    </section>
    <section>
      <h2>5. Disclosures &amp; Transfers</h2>
      <p>
        1. We have put in place contractual and other organizational safeguards
        with our agents to ensure a proper level of protection of your Personal
        Information (see further &quot;Security&quot; below). In addition to
        those measures, we will not disclose or transfer your Personal
        Information to third parties without your permission, except as
        specified in this Privacy Policy (see further below).
      </p>
      <p>
        Please note that if you are using the Services at the request or as part
        of an organization, your organization’s account administrator may be
        able to view certain activity and content associated with use of the
        Services, including, but not limited to, elements of your Personally
        Identifiable Information.
      </p>
      <p>
        Your information, including Personal Data, may be transferred to &mdash;
        and maintained on &mdash; computers located outside of your state,
        province, country or other governmental jurisdiction where the data
        protection laws may differ than those from your jurisdiction.
      </p>
      <p>
        If you are located outside United States and choose to provide
        information to us, please note that we transfer the data, including
        Personal Data, to United States and process it there.
      </p>
      <p>
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </p>
      <p>
        We will take all steps reasonably necessary to ensure that your data is
        treated securely and in accordance with this Privacy Policy and no
        transfer of your Personal Data will take place to an organization or a
        country unless there are adequate controls in place including the
        security of your data and other personal information.
      </p>
      <p>
        From time to time we may employ third parties to help us provide and/or
        improve the Services. These third parties may have limited access to
        databases of user information or registered member information solely
        for the purpose of helping us to provide and/or improve the Services and
        they will be subject to contractual restrictions prohibiting them from
        using the information about our users for any other purpose. Such agents
        or third parties do not have any rights to use Personal Information
        beyond what is absolutely necessary to assist us.
      </p>
      <p>
        If we are involved in a merger, acquisition or asset sale, your Personal
        Information may be transferred. We will provide notice before your
        Personal Information is transferred and becomes subject to a different
        Privacy Policy.
      </p>
    </section>
    <section>
      <h2>6. Security</h2>
      <p>
        The security of your Personal Information is important to us. We use
        commercially reasonable efforts to store and maintain your Personal
        Information in a secure environment. We take technical, contractual,
        administrative, and physical security steps designed to protect Personal
        Information that you provide to us. We have implemented procedures
        designed to limit the dissemination of your Personal Information to only
        such designated staff as are reasonably necessary to carry out the
        stated purposes we have communicated to you.
      </p>
      <p>
        You are also responsible for helping to protect the security of your
        Personal Information. For instance, never give out your password, and
        safeguard your user name, password and personal credentials when you are
        using the Services, so that other people will not have access to your
        Personal Information. Furthermore, you are responsible for maintaining
        the security of any personal computing device on which you utilize the
        Services.
      </p>
    </section>
    <section>
      <h2>7. Sharing Information with Third Parties</h2>
      <p>
        You may be able to direct that Personal Information be shared with third
        parties through use of the Services. We will not permit any such
        transfer without your express opt-in consent. The privacy policies of
        these third parties are not under our control and may differ from ours.
        The use of any information that you may provide to any third parties
        will be governed by the privacy policy of such third party or by your
        independent agreement with such third party, as the case may be.
        Further, such third parties may store the transferred Personal
        Jurisdiction in a location with privacy and other laws different than
        those that apply to Slab. If you have any doubts about the privacy of
        the information you are providing to a third party, we recommend that
        you contact that third party directly for more information or to review
        its privacy policy.
      </p>
    </section>
    <section>
      <h2>8. Retention</h2>
      <p>
        We will keep your Personal Information only for as long as it remains
        necessary for the purposes set forth in this Privacy Policy, which may
        extend beyond the termination of our relationship with you. We will
        retain and use your Personal Information to the extent necessary to
        comply with our legal obligations (for example, if we are required to
        retain your data to comply with applicable laws), resolve disputes, and
        enforce our legal agreements and policies. All retained Personal
        Information will remain subject to the terms of this Privacy Policy.
      </p>
    </section>
    <section>
      <h2>9. Amendment of this Privacy Policy</h2>
      <p>
        We reserve the right to change this Privacy Policy at any time. If we
        decide to change this Privacy Policy in the future, we will post or
        provide appropriate notice. Except as explained below, any non-material
        change (such as clarifications) to this Privacy Policy will become
        effective on the date the change is posted, and any material changes
        will become effective 30 days from their posting on {privacy} or via
        email to your listed email address. Unless stated otherwise, our current
        Privacy Policy applies to all Personal Information that we have about
        you and your account. The date on which the latest update was made is
        indicated at the top of this document. We recommend that you print a
        copy of this Privacy Policy for your reference and revisit this policy
        from time to time to ensure you are aware of any changes. Your continued
        use of the Services signifies your acceptance of any changes.
      </p>
      <p>
        If applicable law requires your opt in consent to any particular
        amendment to this Privacy Policy, the amendment will not apply to your
        Personal Information until we receive such consent from you. To the
        extent we cannot provide some or all of the Services without your
        consent to such amendment to the Privacy Policy, your decision not to
        consent may result in our having to limit your ability to use certain
        aspects of the Services.
      </p>
    </section>
    <section>
      <h2>10. Access and Accuracy; Correcting Personal Information</h2>
      <p>
        You have the right to access the Personal Information we hold about you
        in order to verify the Personal Information we have collected in respect
        to you and to have a general account of our uses of that information.
        Upon receipt of your written request, we will provide you with a copy of
        your Personal Information, although in certain limited circumstances we
        may not be able to make all relevant information available to you, such
        as where that information also pertains to another user. In such
        circumstances we will provide reasons for the denial to you upon
        request. We will endeavor to deal with all requests for access and
        modifications in a timely manner.
      </p>
      <p>
        We will make every reasonable effort to keep your Personal Information
        accurate and up-to-date, and we will provide you with mechanisms to
        correct, amend, delete or limit the use of your Personal Information. As
        appropriate, this amended Personal Information will be transmitted to
        those parties to which we are permitted to disclose your information.
        Having accurate Personal Information about you enables us to give you
        the best possible service.
      </p>
      <p>In certain circumstances, you have the right:</p>
      <ul>
        <li>
          To access and receive a copy of the Personal Data we hold about you
        </li>
        <li>To rectify any Personal Data held about you that is inaccurate</li>
        <li>To request the deletion of Personal Data held about you</li>
      </ul>
      <p>
        You have the right to data portability for the information you provide
        to us. You can request to obtain a copy of your Personal Information in
        a commonly used electronic format so that you can manage and move it.
      </p>
      <p>
        Please note that we may ask you to verify your identity before
        responding to such requests.
      </p>
      <p>
        Under California Civil Code Sections 1798.83-1798.84, California
        residents are entitled to ask us for a notice identifying the categories
        of Personal Information which we share with our affiliates and/or third
        parties for marketing purposes, and providing contact information for
        such affiliates and/or third parties. If you are a California resident
        and would like a copy of this notice, please submit a written request
        to: {support}.
      </p>
    </section>
    <section>
      <h2>11. Service Providers</h2>
      <p>
        We may employ third party companies and individuals to facilitate our
        Services (&quot;Service Providers&quot;), to provide the Services on our
        behalf, to perform Service-related services or to assist us in analyzing
        how our Services is used. These third parties have access to your
        Personal Information only to perform these tasks on our behalf and are
        obligated not to disclose or use it for any other purpose.
      </p>
      <p>
        A list of our current Service Providers can be found at {providers}. The
        Service Provider List will be updated from time-to-time, as Service
        Providers are added and removed. The Service Provider List contains
        instructions on how to sign up to receive an email from us when updates
        are made the Service Provider List.
      </p>
    </section>
    <section>
      <h2>12. Cookie Policy</h2>
      <h3>What are cookies?</h3>
      <p>
        Cookies are small text files containing a string of characters that can
        be placed on your computer or mobile device that uniquely identify your
        browser or device.
      </p>
      <h3>What are cookies used for?</h3>
      <p>
        Cookies allow a site or services to know if your computer or device has
        visited that site or service before. Cookies can then be used to help
        understand how the site or service is being used, help you navigate
        between pages efficiently, help remember your preferences, and generally
        improve your browsing experience. Cookies can also help ensure marketing
        you see online is more relevant to you and your interests.
      </p>
      <h3>What types of cookies do we use?</h3>
      <p>
        There are generally four categories of cookies: &quot;
        <u>Strictly Necessary</u>,&quot; &quot;<u>Performance</u>,&quot; &quot;
        <u>Functionality</u>,&quot; and &quot;<u>Targeting</u>.&quot; We, or our
        Service Providers, routinely use all four categories of cookies on the
        Service. You can find out more about each cookie category below.
      </p>
      <ol>
        <li>
          Strictly Necessary Cookies. These cookies are essential, as they
          enable you to move around the Service and use its features, such as
          accessing logged in or secure areas. Because these cookies are
          essential, they cannot be disabled.
        </li>
        <li>
          Performance Cookies. These cookies collect information about how you
          have used the Service, for example, information related to the unique
          username you have provided, so that less strain is placed on our
          backend infrastructure. These cookies may also be used to allow us to
          know that you have logged in so that we can serve you fresher content
          than a user who has never logged in. We, or our Service Providers,
          also use cookies to track aggregate Service usage in an anonymized
          fashion and experiment with new features and changes on the Service.
          The information collected is used to improve how the Service works.
        </li>
        <li>
          Functionality Cookies. These cookies allow us to remember how you’re
          logged in, whether you chose to no longer see advertisements, when you
          logged in or out, and the state or history of Service tools you’ve
          used. These cookies also allow us to tailor the Service to provide
          enhanced features and content for you and to remember how you’ve
          customized the Service in other ways. The information these cookies
          collect are anonymous, and they are not used to track your browsing
          activity on other sites or services.
        </li>
        <li>
          Targeting Cookies. Us, our advertising partners or other third party
          partners may use these types of cookies to deliver advertising that is
          relevant to your interests. These cookies can remember that your
          device has visited a site or service, and may also be able to track
          your device’s browsing activity on other sites or services other than
          ours. This information may be shared with organizations outside of
          ours, such as advertisers and/or advertising networks to deliver the
          advertising, and to help measure the effectiveness of an advertising
          campaign, or other business partners for the purpose of providing
          aggregate Service usage statistics and aggregate Service testing.
        </li>
      </ol>
      <h3>How long will cookies stay on my device?</h3>
      <p>
        The length of time a cookie will stay on your computer or mobile device
        depends on whether it is a &quot;<u>persistent</u>&quot; or &quot;
        <u>session</u>&quot; cookie. Session cookies will only stay on your
        device until you stop browsing. Persistent cookies stay on your computer
        or mobile device until they expire or are deleted.
      </p>
      <h3>How to control and review cookies?</h3>
      <p>
        If you do not want to receive cookies, you can change your browser
        settings on your computer or other device you’re using to access our
        Services. If you use our Services without changing your browser
        settings, we’ll assume that you’re happy to receive all cookies on our
        website. Most browsers also provide functionality that lets you review
        and erase cookies. To find out more about cookies, including how to see
        what cookies have been set and how to manage and delete them, visit{' '}
        <a href="https://www.allaboutcookies.org">
          https://www.allaboutcookies.org/
        </a>
        .
      </p>
      <p>
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Services.
      </p>
    </section>
    <section>
      <h2>13. EU-U.S. and Swiss-U.S. Privacy Shield</h2>
      <p>
        Slab complies with the EU-U.S. Privacy Shield Framework and the
        Swiss-U.S. Privacy Shield Framework as set forth by the U.S. Department
        of Commerce regarding the collection, use, and retention of personal
        information from European Union member countries and Switzerland
        transferred to the United States pursuant to Privacy Shield. Slab has
        certified that it adheres to the Privacy Shield Principles with respect
        to such data. If there is any conflict between the policies in this
        privacy policy and data subject rights under the Privacy Shield
        Principles, the Privacy Shield Principles shall govern. To learn more
        about the Privacy Shield program, and to view our certification page,
        please visit{' '}
        <a
          href="https://www.privacyshield.gov/"
          target="_blank"
          rel="noreferrer"
        >
          https://www.privacyshield.gov/
        </a>
        .
      </p>
      <p>
        With respect to personal information received or transferred pursuant to
        the Privacy Shield Framework, Slab is subject to the regulatory
        enforcement powers of the U.S. Federal Trade Commission.
      </p>
      <p>
        Pursuant to the Privacy Shield Frameworks, EU and Swiss individuals have
        the right to obtain our confirmation of whether we maintain personal
        information relating to you in the United States. Upon request, we will
        provide you with access to the personal information that we hold about
        you. You may also may correct, amend, or delete the personal information
        we hold about you. An individual who seeks access, or who seeks to
        correct, amend, or delete inaccurate data transferred to the United
        States under Privacy Shield, should direct their query to {support}. If
        requested to remove data, we will respond within a reasonable timeframe.
      </p>
      <p>
        We will provide an individual opt-out choice, or opt-in for sensitive
        data, before we share your data with third parties other than our
        agents, or before we use it for a purpose other than which it was
        originally collected or subsequently authorized. To request to limit the
        use and disclosure of your personal information, please submit a written
        request to {support}.
      </p>
      <p>
        In certain situations, Slab may be required to disclose personal
        information in response to lawful requests by public authorities,
        including to meet national security or law enforcement requirements.
      </p>
      <p>
        Slab&quot;s accountability for personal data that it receives in the
        United States under the Privacy Shield and subsequently transfers to a
        third party is described in the Privacy Shield Principles. In
        particular, Slab remains responsible and liable under the Privacy Shield
        Principles if third-party agents that it engages to process the personal
        data on its behalf do so in a manner inconsistent with the Principles,
        unless Slab proves that it is not responsible for the event giving rise
        to the damage.
      </p>
      <p>
        In compliance with the Privacy Shield Principles, Slab commits to
        resolve complaints about your privacy and our collection or use of your
        personal information transferred to the United States pursuant to
        Privacy Shield. European Union and Swiss individuals with Privacy Shield
        inquiries or complaints should first contact Slab by email at {support}{' '}
        or by telephone at {phone} or by mail at:
      </p>
      {mail}
      <p>
        Slab has further committed to refer unresolved privacy complaints under
        the Privacy Shield Principles to an independent dispute resolution
        mechanism, the BBB EU PRIVACY SHIELD. If you do not receive timely
        acknowledgment of your complaint, or if your complaint is not
        satisfactorily addressed, please visit{' '}
        <a
          target="_blank"
          href="https://www.bbb.org/EU-privacy-shield/for-eu-consumers"
          rel="noreferrer"
        >
          https://www.bbb.org/EU-privacy-shield/for-eu-consumers
        </a>{' '}
        for more information and to file a complaint. This service is provided
        free of charge to you.
      </p>
      <p>
        If your Privacy Shield complaint cannot be resolved through the above
        channels, under certain conditions, you may invoke binding arbitration
        for some residual claims not resolved by other redress mechanisms. See
        Privacy Shield Annex 1 at{' '}
        <a
          target="_blank"
          href="https://www.privacyshield.gov/article?id=ANNEX-I-introduction"
          rel="noreferrer"
        >
          https://www.privacyshield.gov/article?id=ANNEX-I-introduction
        </a>
        .
      </p>
    </section>
    <section>
      <h2>14. Contact Us</h2>
      <p>
        You can help by keeping us informed of any changes such as a change of
        email address or telephone number. If you would like to access your
        information, if you have any questions, comments or suggestions, if you
        find any errors in our information about you, or if you have a complaint
        concerning our compliance with applicable privacy laws, please contact
        us at {support} or by mail at:
      </p>
      {mail}
    </section>
    <hr />
    <section>
      <h2 id="gdpr">
        ADDITIONAL INFORMATION FOR USERS IN THE EUROPEAN ECONOMIC AREA
      </h2>
      <h3>Personal Information</h3>
      <p>
        References to &quot;<u>Personal Information</u>&quot; in this Privacy
        Policy are equivalent to &quot;<u>personal data</u>&quot; governed by
        European data protection legislation.
      </p>
      <h3>Controller</h3>
      <p>
        Slab is the controller of your personal information for purposes of
        European data protection legislation.
      </p>
      <h3>Lawful Basis for Data Processing</h3>
      <p>
        We will only collect, store, and process your personal information where
        a lawful basis for such processing exists, which will typically fall
        under one of the following scenarios:
      </p>
      <ol>
        <li>
          You, the Data Subject, have given consent to the processing of your
          personal information for one or more specific purposes, for example by
          consenting to terms and conditions on our website, and consenting to
          the terms of this Privacy Policy;
        </li>
        <li>
          Processing is necessary for the performance of a contract to which
          you, the Data Subject, are party;
        </li>
        <li>
          Processing is necessary for compliance with a legal obligation to
          which we as a Controller is subject;
        </li>
        <li>
          Processing is necessary for the purposes of the legitimate interests
          pursued by us as the Controller, or by a third party, except where
          such interests are overridden by the interests or fundamental rights
          and freedoms of you, the Data Subject, which require protection of
          your personal information. The legitimate interests that underlie our
          processing of personal data include research and development,
          marketing and promoting the Services, and protecting our legal rights
          and interests.
        </li>
      </ol>
      <h3>Use for new purposes</h3>
      <p>
        We may use your personal information for reasons not described in this
        Privacy Policy where permitted by law and the reason is compatible with
        the purpose for which we collected it.
      </p>
      <h3>Retention</h3>
      <p>
        We will only retain your personal information for as long as necessary
        to fulfill the purposes we collected it for, including for the purposes
        of satisfying any legal, accounting, or reporting requirements. For
        example, we keep account information for as long as the account is
        active, and for a reasonable period thereafter to ease reactivation if
        you choose to reactivate your account. If the Services are made
        available to you through an organization, e.g., your employer, we retain
        your information as long as required by the administrator of your
        account on their behalf.
      </p>
      <p>
        To determine the appropriate retention period for personal information,
        we consider the amount, nature, and sensitivity of the personal
        information, the potential risk of harm from unauthorized use or
        disclosure of your personal information, the purposes for which we
        process your personal information and whether we can achieve those
        purposes through other means, and the applicable legal requirements.
      </p>
      <p>
        In some circumstances we may anonymize your personal information (so
        that it can no longer be associated with you) in which case we may use
        this information indefinitely without further notice to you.
      </p>
      <h3>Your rights</h3>
      <p>
        European data protection laws give you certain rights regarding your
        personal information. You may ask us to take the following actions in
        relation to your personal information that we hold:
      </p>
      <ul>
        <li>
          &quot;<u>Opt-out</u>&quot;. Stop sending you direct marketing
          communications. You may continue to receive Service-related and other
          non-marketing emails.
        </li>
        <li>
          &quot;<u>Access</u>&quot;. Provide you with information about our
          processing of your personal information and give you access to your
          personal information.
        </li>
        <li>
          &quot;<u>Correct</u>&quot;. Update or correct inaccuracies in your
          personal information.
        </li>
        <li>
          &quot;<u>Delete</u>&quot;. Delete your personal information.
        </li>
        <li>
          &quot;<u>Transfer</u>&quot;. Transfer a machine-readable copy of your
          personal information to you or a third party of your choice.
        </li>
        <li>
          &quot;<u>Object</u>&quot;. Object to our reliance on our legitimate
          interests as the basis of our processing of your personal information
          that impacts your rights.
        </li>
        <li>
          &quot;<u>Withdraw Consent</u>&quot;. If our processing is based on
          your consent, you have the right to withdraw such consent at any time.
        </li>
      </ul>
      <p>
        You may also have the right to make a GDPR complaint to the relevant
        Supervisory Authority. A list of Supervisory Authorities is available{' '}
        <a
          target="_blank"
          href="https://edpb.europa.eu/about-edpb/board/members_en"
          rel="noreferrer"
        >
          here.
        </a>
      </p>
      <p>
        You can submit these requests by email to: {support}. We may request
        specific information from you to help us confirm your identity and
        process your request. Applicable law may require or permit us to decline
        your request. If we decline your request, we will tell you why, subject
        to legal restrictions. If you would like to submit a complaint about our
        use of your personal information or response to your requests regarding
        your personal information, you may contact us as described in Section 13
        of our Privacy Notice or submit a complaint to the data protection
        regulator in your jurisdiction. You can find your data protection
        regulator{' '}
        <a
          target="_blank"
          href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <h3>Cross-Border Data Transfer</h3>
      <p>
        Whenever we transfer your personal information out of the EEA to
        countries not deemed by the European Commission to provide an adequate
        level of personal information protection, the transfer will be based on
        either use of approved standard contractual clauses or one of the
        safeguards recognized by the European Commission as providing adequate
        protection for personal information, where required by EU data
        protection legislation.
      </p>
    </section>
  </LegalLayout>
);

export default Privacy;
